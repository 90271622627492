import React from 'react'

const tableStyle = {
    textAlign: 'center',
}

const Version3And5Description = ({namespace, name}) => {
    if (namespace.trim().length > 0) {
        return (
            <div style={tableStyle}>
                <table align="center">
                    <tbody>
                        <tr><td><strong>Namespace</strong></td></tr>
                        <tr><td>{namespace}</td></tr>
                        <tr><td><strong>Name</strong></td></tr>
                        <tr><td>{name}</td></tr>
                    </tbody>
                </table>
            </div>
        );

    } else {
        return (
            <div></div>
        );
    }
}

export default Version3And5Description;
