import React from 'react'
import classNames from 'classnames';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';

import { getFileDownloadUUIDV1, getFileDownloadUUIDV3, getFileDownloadUUIDV4, getFileDownloadUUIDV5 } from "../actions";


const styles = theme => ({
    leftIcon: {
        paddingLeft: '10px',
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 60,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    content: {
        width: '100%',
        backgroundColor: 'red',
        display: 'block',
    }
});

class DownloadButton extends React.Component {
    constructor(props) {
        super(props);
        const { version } = this.props;

        this.state = {
            version : version,
            numRows : '1',
            labelWidth: 0,
        };
    }

    componentDidMount() {
        // this.setState({
        //     labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
        // });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleClick = (e) => {
        e.preventDefault();
        if (this.state.version === 'version1') {
            this.props.getFileDownloadUUIDV1(this.state.numRows);
        } else if (this.state.version === 'version3') {
            this.props.getFileDownloadUUIDV3(this.state.numRows);
        }else if (this.state.version === 'version4') {
            this.props.getFileDownloadUUIDV4(this.state.numRows);
        } else if (this.state.version === 'version5') {
            this.props.getFileDownloadUUIDV5(this.state.numRows);
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <form className={classes.root} autoComplete="off">
                <FormControl className={classes.formControl}>
                    <Select value={this.state.numRows}
                            onChange={this.handleChange}
                            input={<Input name="numRows" id="numFile-simple" />}>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={200}>200</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <Button variant="contained" color="default" onClick={(e) => this.handleClick(e)}>
                        Download
                        <SaveAlt className={classNames(classes.leftIcon, classes.iconSmall)}/>
                    </Button>
                </FormControl>
            </form>
        );
    }
}

DownloadButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(null, {getFileDownloadUUIDV1, getFileDownloadUUIDV3, getFileDownloadUUIDV4, getFileDownloadUUIDV5})(DownloadButton));
