import React from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import MainMenuBar from '../components/MainMenuBar';
import BannerUUID from "../components/BannerUUID";
import DownloadButton from "../components/DownloadButton";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        color: 'white',
        backgroundColor: '#0099DA',
        height: '90%',

        display: 'flex',
        flexDirection: 'column',
    },
    paperText: {
        height: '80%',
        width: '100%',
    },
    paperButton: {
        width: '100%',
    },
    paperSponsor : {
        padding: theme.spacing.unit * 2,
        color: theme.palette.text.secondary,
    },
    square: {
        borderRadius: '0px 0px',
        backgroundColor: 'red'
    },
    textColor : {
        color: '#01456f',
    }
});

class RootLayout extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <MainMenuBar/>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Switch>
                            <Route path="/" exact render={(props) => <BannerUUID {...props} version={'version4'} />}/>
                            <Route path="/version1" render={(props) => <BannerUUID {...props} version={'version1'} />}/>
                            <Route path="/version3" render={(props) => <BannerUUID {...props} version={'version3'} />}/>
                            <Route path="/version4" render={(props) => <BannerUUID {...props} version={'version4'} />}/>
                            <Route path="/version5" render={(props) => <BannerUUID {...props} version={'version5'} />}/>
                            <Route path="/nil" render={(props) => <BannerUUID {...props} version={'version-nil'} />}/>
                            <Route path="/guid" render={(props) => <BannerUUID {...props} version={'version-guid'} />}/>
                        </Switch>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper className={classes.paper}>
                            <div className={classes.paperText}>
                                <Typography variant="h6" component="h6" className={classes.textColor}>
                                    What is version 1 UUID?
                                </Typography>
                                <br/>
                                A Version 1 UUID is a universally unique identifier that is generated using a timestamp and
                                the MAC address of the computer on which it was generated.
                            </div>
                            <div className={classes.paperButton}>
                                <DownloadButton version="version1"/>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper className={classes.paper}>
                            <div className={classes.paperText}>
                                <Typography variant="h6" component="h6" className={classes.textColor}>
                                    What is a version 3 UUID?
                                </Typography>
                                <p>A Version 2 UUID is a universally unique identifier that is generated using the hash
                                    algorithm ( md5 128 bits ) of namespace and name. The namespace identifiers are UUIDs
                                    like Domain Name System (DNS), Object Identifiers (OIDs), URLs etc.</p>
                            </div>
                            <div className={classes.paperButton}>
                                <DownloadButton version="version3"/>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper className={classes.paper}>
                            <div className={classes.paperText}>
                                <Typography variant="h6" component="h6" className={classes.textColor}>
                                    What is a version 4 UUID?
                                </Typography>
                                <p>A Version 4 UUID is a universally unique identifier that is generated using random numbers.
                                The Version 4 UUIDs produced by this site were generated using a secure random number
                                    generator.</p>
                            </div>
                            <div className={classes.paperButton}>
                                <DownloadButton version="version4"/>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper className={classes.paper}>
                            <div className={classes.paperText}>
                                <Typography variant="h6" component="h6" className={classes.textColor}>
                                    What is a version 5 UUID?
                                </Typography>
                                <p>A Version 5 UUID is a universally unique identifier that is generated using the hash
                                    algorithm ( sha-1 160 bits ) of namespace and name. The namespace identifiers are UUIDs
                                    like Domain Name System (DNS), Object Identifiers (OIDs), URLs etc.</p>
                            </div>
                            <div className={classes.paperButton}>
                                <DownloadButton version="version5"/>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className={classes.paperSponsor}>
                            <div align="center"><img src="logo.png" alt="OpenKM logo"/></div>
                            <p><strong>The ONLINE UUID ( GUID ) GENERATOR SERVICE is sponsored by <a href="https://www.openkm.com">OpenKM</a></strong>.</p>
                            <p><strong>OpenKM</strong> is a Document and Record Management System.</p>
                            <p>The UUIDs generated by this site are provided AS IS without warranty of any kind, not even the warranty that the generated UUIDs are actually unique. You are responsible for using the UUIDs and assume any risk inherent to using them. You are not permitted to use the UUIDs generated by this site if you do not agree to these terms. Do not use any UUIDs found on cached versions of this page.</p>
                            <p>The UUIDs generated by this site conform to <a href="https://www.ietf.org/rfc/rfc4122.txt">RFC 4122</a> whenever possible.</p>
                            <p><a href="https://en.wikipedia.org/wiki/Universally_unique_identifier">Read more about UUIDs at Wikipedia.</a></p>
                            <p><a href="/swagger-ui.html">Check out our developer API.</a></p>
                            <p>Copyright © Open Knowledge Management System S.L. All Rights Reserved. <a href="https://www.openkm.com/en/contact.html">Contact Us.</a></p>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className={classes.paperSponsor}>
                            <p>Both <strong>UUIDv3</strong> and <strong>UUIDv5</strong> have the nice property that different systems can generate the same UUID using the same namespace and name. These are basically <strong>used for creating hierarchical UUIDs</strong>.</p>
                            <p>As both the hash functions MD5 and SHA1 have both been broken, <strong>using v5 is recommended</strong>. If you just need <strong>simple UUID generation, type 4 can be OK for the general use-case</strong>.</p>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

RootLayout.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RootLayout);
