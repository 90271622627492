import React from 'react';
import { HashRouter } from "react-router-dom";
import RootLayout from './RootLayout'

class App extends React.Component {
    render() {
        return (
            <div>
                <HashRouter>
                    <RootLayout />
                </HashRouter>
            </div>
        );
    }
}

//await download;
export default App;
