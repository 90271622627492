import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Refresh from '@material-ui/icons/Refresh';

import Version3And5Description from './Version3And5Description';
import CopyButton from './CopyButton';

import {getUUIDV1, getUUIDV3, getUUIDV4, getUUIDV5, getUUIDEmpty, getFileDownloadUUIDV1, getFileDownloadUUIDV4} from "../actions";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 4,
        paddingBottom: theme.spacing.unit * 2,
        borderRadius: 0,
        textAlign: 'center',
        backgroundColor : '#0090ca',
        position: 'relative',
    },
    extraInfo:{
        position: 'absolute',
        top: '40px',
        right: '20px',
    },
    text: {
        color: 'white',
        padding: '16px'
    },
    textBlue: {
        color: '#01456f',
    },
    button: {
        left: '20px'
    },
    generateNew : {
        paddingTop: '15px',
        color: '#01456f',
        fontSize : '1.5rem'
    },
    refreshIcon : {
        position: 'relative',
        top: '3px'
    }
});

class BannerUUID extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            message : '',
            version : props.version,
            uuid : '',
            namespace : '',
            name : 'openkm.com',
            open: false
        };
    }

    refreshUUID = () => {
        if (this.state.version === 'version1') {
            this.props.getUUIDV1();
        } else if (this.state.version === 'version3') {
            this.props.getUUIDV3(this.state);
        } else if (this.state.version === 'version5') {
            this.props.getUUIDV5(this.state);
        } else if (this.state.version === 'version-nil') {
            this.props.getUUIDEmpty();
        } else {
            this.props.getUUIDV4();
        }
    }

    componentDidMount() {
        this.refreshUUID();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.version !== this.state.version) {
            this.refreshUUID();
        }
    }

    componentWillReceiveProps(nextProps) {
        const { version, uuid, namespace } = nextProps;
        if (uuid.length>0 && uuid === this.state.uuid ) {
            if (this.state.version !== version) {
                // Case change of version
                this.setState({
                    version: version
                });
            } else {
                this.refreshUUID(); // Case accessing the component from link itself
            }
        } else {
            let msg = 'Your Version 4 UUID:';
            if (version === 'version1') {
                msg = 'Your Version 1 UUID:';
            } else if (version === 'version3') {
                msg = 'Your Version 3 UUID:';
            } else if (version === 'version5') {
                msg = 'Your Version 5 UUID:';
            } else if (version === 'version-nil') {
                msg = 'Your Nil/Empty UUID:';
            } else if (version === 'version-guid') {
                msg = 'Your GUID:';
            }
            this.setState({
                message: msg,
                version: version,
                uuid: uuid,
                namespace: namespace
            });
        }
    }

    render() {
        const { message, uuid } = this.state;

        if (uuid.length === 0) {
            return <div>Loading please wait...</div>;
        }
        const { classes } = this.props;

        return (
            <Paper className={classes.root} elevation={1}>
                <div>
                    <Typography variant="h3" component="h1" className={classes.text}>
                        Online UUID generator
                    </Typography>

                    <Typography variant="h6" component="h5" className={classes.textBlue}>
                        <p>{message}</p>
                        <CopyButton variant="success" {...{uuid}}/>
                    </Typography>

                    <Link component="button" className={classes.generateNew} onClick={() => { this.refreshUUID(); }}>
                        <Refresh className={classes.refreshIcon} /> Generate new
                    </Link>
                    </div>
                <div className={classes.extraInfo}>
                    <Typography variant="h6" component="h6" className={classes.textBlue}>
                        <Version3And5Description {...this.state}/>
                    </Typography>
                </div>
            </Paper>
        );
    }
}

BannerUUID.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        uuid: state.uuidData.uuid,
        namespace: state.uuidData.namespace,
        version: ownProps.version
    };
};

export default withStyles(styles)(connect(mapStateToProps, {getUUIDV1, getUUIDV3, getUUIDV4, getUUIDV5, getUUIDEmpty, getFileDownloadUUIDV1, getFileDownloadUUIDV4})(BannerUUID));
