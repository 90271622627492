import React from 'react';
import PropTypes from "prop-types";

import CopyToClipboard from 'react-copy-to-clipboard';
import {withStyles} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FileCopy from '@material-ui/icons/FileCopy';
import green from '@material-ui/core/colors/green';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import classNames from "classnames";

const styles = theme => ({
    button: {
        left: '20px'
    },
    close: {
        padding: theme.spacing.unit / 2,
    },
    leftIcon: {
        paddingLeft: '10px',
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    success: {
        backgroundColor: green[600],
    },
    text: {
        color: 'white',
    },
});

function CustomSnackbarContent(props) {
    const { classes, className, message, onClose } = props;

    return (
        <SnackbarContent
            className={classNames(classes.success, className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                   {message}
                </span>
            }
            action={[
                <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose} >
                    <CloseIcon />
                </IconButton>
                ,
            ]}
        />
    );
}

const SnackbarContentWrapper = withStyles(styles)(CustomSnackbarContent);

class CopyButton extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
        };
    }

    handleClick = () => {
        this.setState({ open: true });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ open: false });
    };

    render() {
        const { uuid } = this.props;
        const { classes } = this.props;

        return (
            <div>
                <Typography variant="h4" component="h1" className={classes.text}>
                    {uuid}
                <CopyToClipboard onCopy={this.onCopy} text={uuid}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClick}>
                        Copy
                        <FileCopy className={classNames(classes.leftIcon, classes.iconSmall)}/>
                    </Button>
                </CopyToClipboard>
                </Typography>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.open}
                    autoHideDuration={3000}
                    onClose={this.handleClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                >
                    <SnackbarContentWrapper message="UUID copied in the clipboard" className={classes.success} onClose={this.handleClose}/>
                </Snackbar>
            </div>
        );
    }
}

CopyButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CopyButton);
