import uuidApi from '../apis/uuidApi';
import fileDownload from 'js-file-download';
import {GET_UUID_V1, GET_UUID_V3, GET_UUID_V4, GET_UUID_V5, GET_UUID_EMPTY, GET_FILE} from './types'

export const getUUIDV1 = () => {
    return async (dispatch) => {
        const response = await uuidApi.get('/version1');
        dispatch({type: GET_UUID_V1, payload: response.data});
    }
};

export const getUUIDV3 = ({name}) => {
    return async (dispatch) => {
        // First create an uuid for the namespace
        // Then create an uuid based in name ( name is an static value openkm.com )
        const response = await uuidApi.get('/version1');
        const namespace = response.data;
        const response2 = await uuidApi.get('/version3', {params: {namespace, name}});
        const responseData = {
            uuid : response2.data,
            namespace: namespace
        }
        dispatch({type: GET_UUID_V3, payload: responseData});
    }
};

export const getUUIDV4 = () => {
    return async (dispatch) => {
        const response = await uuidApi.get('/version4');
        dispatch({type: GET_UUID_V4, payload: response.data});
    }
};

export const getUUIDV5 = ({name}) => {
    return async (dispatch) => {
        // First create an uuid for the namespace
        // Then create an uuid based in name ( name is an static value openkm.com )
        const response = await uuidApi.get('/version1');
        const namespace = response.data;
        const response2 = await uuidApi.get('/version5', {params: {namespace, name}});
        const responseData = {
            uuid : response2.data,
            namespace: namespace
        }
        dispatch({type: GET_UUID_V5, payload: responseData});
    }
};

export const getUUIDEmpty = () => {
    return async (dispatch) => {
        dispatch({type: GET_UUID_EMPTY, payload: '00000000-0000-0000-0000-000000000000'});
    }
};

// Inspired by https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
export const getFileDownloadUUIDV1 = (numRows) => {
    return async (dispatch) => {
        await uuidApi({
            url: `/version1/file/${numRows}`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'version1.txt');
            document.body.appendChild(link);
            link.click();
        });
        dispatch({type: GET_FILE});
    }
};

// To download files https://www.npmjs.com/package/js-file-download
export const getFileDownloadUUIDV3 = (numRows) => {
    return async (dispatch) => {
        await uuidApi({
            url: `/version3/file/${numRows}`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            fileDownload(response.data, 'version3.csv','text/csv');
        });
        dispatch({type: GET_FILE});
    }
};

// To download files https://www.npmjs.com/package/js-file-download
export const getFileDownloadUUIDV4 = (numRows) => {
    return async (dispatch) => {
        await uuidApi({
            url: `/version4/file/${numRows}`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            fileDownload(response.data, 'version4.txt','text/plain');
        });
        dispatch({type: GET_FILE});
    }
};

// To download files https://www.npmjs.com/package/js-file-download
export const getFileDownloadUUIDV5 = (numRows) => {
    return async (dispatch) => {
        await uuidApi({
            url: `/version5/file/${numRows}`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            fileDownload(response.data, 'version5.csv','text/csv');
        });
        dispatch({type: GET_FILE});
    }
};
