import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const styles = {
    root: {
        flexGrow: 1,
        textAlign: 'center',
    },
    appBar: {
        backgroundColor: '#01456f'
    },
    grow: {
        flexGrow: 1,
        padding: 20
    },
    menuLink: {
        marginLeft: -12,
        marginRight: 20,
        padding: 20,
        color: 'white'
    }
};

function MainMenuBar(props) {
    const { classes } = props;
    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Typography color="inherit" className={classes.grow}>
                        <Link className={classes.menuLink} component={RouterLink} to="/version5">
                            Version 5 UUID
                        </Link>
                        <Link className={classes.menuLink} component={RouterLink} to="/version4">
                            Version 4 UUID
                        </Link>
                        <Link className={classes.menuLink} component={RouterLink} to="/version3">
                            Version 3 UUID
                        </Link>
                        <Link className={classes.menuLink} component={RouterLink} to="/version1">
                            Version 1 UUID
                        </Link>
                        <Link className={classes.menuLink} component={RouterLink} to="/nil">
                            Nil/Empty UUUID
                        </Link>
                        <Link className={classes.menuLink} component={RouterLink} to="/guid">
                            GUID Generator
                        </Link>
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
}

MainMenuBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainMenuBar);
