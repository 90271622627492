import {
    GET_UUID_V5,
    GET_UUID_V4,
    GET_UUID_V3,
    GET_UUID_V1,
    GET_UUID_EMPTY
} from '../actions/types';

export default (state = {uuid:'', namespace:''}, action) => {
    switch (action.type) {
        case GET_UUID_V5:
            return action.payload;
        case GET_UUID_V4:
            return {uuid: action.payload, namespace: ''};
        case GET_UUID_V3:
            return action.payload;
        case GET_UUID_V1:
            return {uuid: action.payload, namespace: ''};
        case GET_UUID_EMPTY:
            return {uuid: action.payload, namespace: ''};
        default:
            return state;
    }
}
